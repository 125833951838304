import * as React from "react"
import { Link, HeadFC } from "gatsby"
import Layout from "../components/layout/layout"
import Policy from "../components/policy/policy"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import SEO from "../components/seo/seo"

const PrivacyPage = () => {
  return (
    <Layout chat={true}>
        <Policy>
        <div
    className="privacy body2"
  >
    <h2>Privacy policy</h2>

    <p className="c36" id="h.30j0zll">
      <span className="c0"
        >Effective Date / Date of Last Revision: &nbsp;May 24, 2022</span
      >
    </p>

    <ol className="c8 lst-kix_list_23-0 start">
      <li className="c10 li-bullet-0">
        <span className="c4"><a href="#p1">WHO WE ARE</a></span>
      </li>
      <li className="c10 li-bullet-0">
        <span className="c4"><a href="#p2">HOW TO CONTACT US</a></span>
      </li>
      <li className="c10 li-bullet-0">
        <span className="c4"><a href="#p3">WHY AND HOW WE COLLECT DATA</a></span>
      </li>
      <li className="c10 li-bullet-0">
        <span className="c4"
          ><a href="#p4">PROTECTING YOUR PRIVACY GENERALLY</a></span
        >
      </li>
      <li className="c10 li-bullet-0">
        <span className="c4"><a href="#p5">CHILDREN&rsquo;S PRIVACY</a></span>
      </li>
      <li className="c10 li-bullet-0">
        <span className="c4"><a href="#p6">TYPES OF INFORMATION WE COLLECT</a></span>
      </li>
      <li className="c10 li-bullet-0">
        <span className="c4"><a href="#p7">HOW WE USE DATA</a></span>
      </li>
      <li className="c10 li-bullet-0">
        <span className="c4"
          ><a href="#p8">INFORMATION WE SHARE WITH THIRD PARTIES</a></span
        >
      </li>
      <li className="c10 li-bullet-0">
        <span className="c4"
          ><a href="#p9">HOW TO ACCESS &amp; CONTROL YOUR PERSONAL DATA</a></span
        >
      </li>
      <li className="c10 li-bullet-0">
        <span className="c4"><a href="#p10">SECURITY</a></span>
      </li>
      <li className="c10 li-bullet-0">
        <span className="c4"><a href="#p11">LINKS TO THIRD-PARTY SITES</a></span>
      </li>
      <li className="c10 li-bullet-0">
        <span className="c4"><a href="#p12">DATA RETENTION</a></span>
      </li>
      <li className="c10 li-bullet-0">
        <span className="c4"><a href="#p13">ACCESSIBILITY</a></span>
      </li>
      <li className="c10 li-bullet-0">
        <span className="c4"><a href="#p14">NOTICE TO NEVADA RESIDENTS</a></span>
      </li>
      <li className="c10 li-bullet-0">
        <span className="c4"><a href="#p15">NOTICE TO CALIFORNIA RESIDENTS</a></span>
      </li>
    </ol>
    <ol className="c8 lst-kix_list_14-0 start" start="1" id="p1">
      <li className="c25 li-bullet-0" id="h.1fob9te">
        <span className="c4">WHO WE ARE</span>
      </li>
    </ol>
    <p className="c55" id="h.3znysh7">
      <span className="c22">HomeFounder (&ldquo;</span
      ><span className="c11">HomeFounder</span
      ><span className="c22">,&rdquo; &ldquo;</span><span className="c11">we</span
      ><span className="c22">,&rdquo; &ldquo;</span><span className="c11">our</span
      ><span className="c22">,&rdquo; or &ldquo;</span><span className="c11">us</span
      ><span className="c22"
        >&rdquo;) is an innovative startup program designed to save home buyers
        money when purchasing new construction homes. &nbsp;HomeFounder brings
        together qualified homebuyers early in the land acquisition, development
        and construction phase and allows them to directly invest in their
        community without outside investors. &nbsp;HomeFounder has launched a
        publicly available website at </span
      ><span className="c22 c38"
        ><a className="c1" href="https://www.homefounder.com"
          >www.homefounder.com</a
        ></span
      ><span className="c22">&nbsp;(&ldquo;</span><span className="c11">Site</span
      ><span className="c22"
        >&rdquo;) where individuals may obtain more information about
        HomeFounder&rsquo;s projects and services (&ldquo;</span
      ><span className="c11">Services</span><span className="c0">&rdquo;). &nbsp;</span>
    </p>
    <p className="c30">
      <span className="c22"
        >Some of the data HomeFounder collects is considered &ldquo;personally
        identifiable information,&rdquo; &ldquo;personal information&rdquo; or
        &ldquo;personal data&rdquo; under applicable law (collectively referred
        to in this Privacy Policy as &ldquo;</span
      ><span className="c11">Personal Data</span
      ><span className="c0"
        >&rdquo;). &nbsp;Generally, Personal Data is information that can be
        reasonably connected to an identified or identifiable individual.
        &nbsp;It does not include deidentified or anonymized data.</span
      >
    </p>
    <p className="c13 c32"><span className="c0"></span></p>
    <p className="c13">
      <span className="c0"
        >The purpose of this Privacy Policy is to disclose to you how we
        collect, use and share information, and how we attempt to safeguard the
        data you provide to us. &nbsp;We are committed to securing data that we
        collect concerning you (&ldquo;</span
      ><span className="c4">you</span
      ><span className="c0"
        >&rdquo;) and complying with applicable data protection and privacy
        laws</span
      ><span className="c22 c56">. &nbsp;</span>
    </p>
    <p className="c30 c32"><span className="c0"></span></p>
    <ol className="c8 lst-kix_list_14-0" start="2" id="p2">
      <li className="c25 li-bullet-0">
          <span className="c4">HOW TO CONTACT US</span>
      </li>
    </ol>
    <p className="c43">
      <span className="c0"
        >If you have any questions regarding data protection or your rights,
        please contact us:</span
      >
    </p>
    <p className="c27 c28" id="h.tyjcwt">
      <span className="c22 c29">HomeFounder</span><span className="c22"><br /></span
      ><span className="c22 c29">by Gemini Studio, Inc.</span
      ><span className="c22"><br /></span
      ><span className="c0 c29"
        >6 Liberty Square, PO Box 266, Boston, MA 02109</span
      >
    </p>
    <p className="c27 c28" id="h.3dy6vkm">
      <span className="c38 c22"
        ><a className="c1" href="mailto:privacy@homefounder.com"
          >team@homefounder.com</a
        ></span
      ><span className="c0">&nbsp;</span>
    </p>
    <p className="c26 c32"><span className="c4"></span></p>
    <ol className="c8 lst-kix_list_14-0" start="3" id="p3">
      <li className="c10 li-bullet-0">
        <span className="c4">WHY AND HOW WE COLLECT DATA</span>
      </li>
    </ol>
    <p className="c46">
      <span className="c0"
        >We collect, process and use Personal Data to allow you to utilize the
        functionality and features we provide on our Site, to provide you with
        information about our Services, to provide our Services and to
        communicate with you. The legal basis for our collection of Personal
        Data is with your consent, for our legitimate business purposes and/or
        to comply with our legal and contractual obligations. &nbsp;We obtain
        Personal Data directly from you, through cookies and other technologies,
        and from third parties as described in this Policy and any other privacy
        notices we may provide to you when you interact with us.</span
      >
    </p>
    <ol className="c8 lst-kix_list_16-0 start" start="1">
      <li className="c28 c44 li-bullet-0">
        <span className="c4">Information and content you provide.</span
        ><span className="c0"
          >&nbsp; We collect the content, communications and other information
          you provide when you use our Site and Services, including when you
          sign up for notifications about upcoming releases, create an account,
          create or share content, and message or otherwise communicate with
          others. &nbsp; &nbsp;</span
        >
      </li>
    </ol>
    <p className="c27 c17"><span className="c0"></span></p>
    <ol className="c8 lst-kix_list_16-0" start="2">
      <li className="c16 li-bullet-0" id="h.1t3h5sf">
        <span className="c4">Usage. &nbsp;</span
        ><span className="c0"
          >We collect data on how you use our Site and Services, such as the
          content you view or engage with, the features you use, the time,
          frequency and duration of activities.
        </span>
      </li>
    </ol>
    <p className="c27 c17"><span className="c0"></span></p>
    <ol className="c8 lst-kix_list_16-0" start="3">
      <li className="c16 li-bullet-0">
        <span className="c4"
          >Information from our service providers and partners.</span
        ><span className="c0"
          >&nbsp; Sometimes our service providers share information with us.
          &nbsp;</span
        >
      </li>
    </ol>
    <p className="c27 c17"><span className="c0"></span></p>
    <ol className="c8 lst-kix_list_16-0" start="4">
      <li className="c16 li-bullet-0">
        <span className="c4">Information from other Site users</span
        ><span className="c0"
          >. We collect content, communications and information that other users
          of the Site provide about you or tag you in.</span
        >
      </li>
    </ol>
    <p className="c27 c17"><span className="c0"></span></p>
    <ol className="c8 lst-kix_list_16-0" start="5">
      <li className="c16 li-bullet-0">
        <span className="c4">Device information.</span
        ><span className="c0"
          >&nbsp; We collect information from and about the computers, phones,
          connected TVs and other web-connected devices that you use to access
          our Site.</span
        >
      </li>
    </ol>
    <p className="c17 c27"><span className="c0"></span></p>
    <ol className="c8 lst-kix_list_14-0" start="4" id="p4">
      <li className="c10 li-bullet-0" id="h.4d34og8">
        <span className="c4">PROTECTING YOUR PRIVACY GENERALLY</span
        ><span className="c0">. &nbsp;</span>
      </li>
    </ol>
    <p className="c26 c32"><span className="c4"></span></p>
    <ol className="c8 lst-kix_list_19-1 start" start="1">
      <li className="c3 li-bullet-0" id="h.2s8eyo1">
        <span className="c4">Industry-Standard Protection</span
        ><span className="c0">.</span><span className="c4">&nbsp;</span
        ><span className="c0"
          >We utilize industry-standard security measures to safeguard the
          information we collect, maintain and use. These measures include
          technical and procedural steps to protect your data from misuse,
          unauthorized access or disclosure, loss, alteration or destruction.
          However, we do not and cannot guarantee the performance or adequacy of
          such security measures.</span
        >
      </li>
      <li className="c3 li-bullet-0" id="h.17dp8vu">
        <span className="c4">Limitation on Access.</span
        ><span className="c0"
          >&nbsp; Access to any Personal Data we collect and store is generally
          restricted to our employees and/or contractors who require it to
          perform a job or other contracted function. We require vendors and
          contractors we work with to use reasonable, industry standard
          protocols to maintain the confidentiality, security, and integrity of
          Personal Data.
        </span>
      </li>
    </ol>
    <ol className="c8 lst-kix_list_14-0" start="5" id="p5">
      <li className="c10 li-bullet-0" id="h.3rdcrjn">
        <span className="c4">CHILDREN&rsquo;S PRIVACY. &nbsp;</span>
      </li>
    </ol>
    <p className="c26 c32"><span className="c0"></span></p>
    <p className="c26">
      <span className="c0"
        >We do not intentionally collect any Personal Data from children under
        the age of eighteen (18) years of age. &nbsp;We do not sell the Personal
        Data of children under the age of eighteen (18) years of age.
        &nbsp;</span
      >
    </p>
    <p className="c26 c32"><span className="c0"></span></p>
    <ol className="c8 lst-kix_list_14-0" start="6" id="p6">
      <li className="c10 li-bullet-0">
        <span className="c4">TYPES OF INFORMATION WE COLLECT</span
        ><span className="c0">. &nbsp;</span>
      </li>
    </ol>
    <p className="c26 c32"><span className="c4"></span></p>
    <ol className="c8 lst-kix_list_20-0 start" start="1">
      <li className="c16 li-bullet-0">
        <span className="c20">Information we collect from you.</span
        ><span className="c0"
          >&nbsp; We may collect the following categories of Personal
          Data:</span
        >
      </li>
    </ol>
    <p className="c27 c32"><span className="c0"></span></p>
    <ul className="c8 lst-kix_list_17-0 start">
      <li className="c2 li-bullet-1">
        <span className="c0"
          >Contact information (name, local and permanent address, telephone
          number, electronic mail address)</span
        >
      </li>
      <li className="c2 li-bullet-2">
        <span className="c0"
          >Your employment information and annual salary amount</span
        >
      </li>
      <li className="c2 li-bullet-2">
        <span className="c0">Your date of birth, gender and gender identity</span>
      </li>
      <li className="c2 li-bullet-1">
        <span className="c0">Your country of domicile</span>
      </li>
      <li className="c2 li-bullet-2">
        <span className="c0">Your social media account names / handles</span>
      </li>
      <li className="c2 li-bullet-1">
        <span className="c0">Any other information you provide to us &nbsp;</span>
      </li>
    </ul>
    <ol className="c8 lst-kix_list_20-0" start="2">
      <li className="c3 li-bullet-0" id="h.26in1rg">
        <span className="c20">Information We Collect Via Tracking Technology.</span
        ><span className="c0">&nbsp; </span>
      </li>
    </ol>
    <p className="c15">
      <span className="c0"
        >We use cookies and other tracking technologies (such as pixels and web
        beacons) (collectively, &ldquo;</span
      ><span className="c4">cookies</span
      ><span className="c0"
        >&rdquo;) to collect information as you navigate the Site. &nbsp;Cookies
        are small files of information that are stored by your web browser
        software on your computer hard drive, mobile or other devices (e.g.,
        smartphones or tablets). &nbsp;</span
      ><span className="c18"
        ><router-link to="/cookies">You may review </router-link></span
      ><span className="c38 c22 c52"
        ><router-link to="/cookies">our Cookie Policy here</router-link></span
      ><span className="c6">.</span><span className="c0">&nbsp; </span>
    </p>
    <p className="c15">
      <span className="c0"
        >You can control the use of cookies at the browser level by setting your
        web browser controls to accept or refuse cookies. &nbsp;If you choose to
        block all bookies (including essential cookies) you may not be able to
        access all or parts of the Site. &nbsp;In addition, most advertising
        networks offer you a way to opt out of targeted advertising.&nbsp; If
        you would like to find out more information on this, please visit the
        websites: </span
      ><span className="c37 c22"
        ><a className="c1" href="http://www.aboutads.info/choices/"
          >http://www.aboutads.info/choices/</a
        ></span
      ><span className="c0">&nbsp;or </span
      ><span className="c22 c37"
        ><a className="c1" href="http://www.youronlinechoices.com"
          >http://www.youronlinechoices.com</a
        ></span
      ><span className="c0">.</span>
    </p>
    <ol className="c8 lst-kix_list_14-0" start="7" id="p7">
      <li className="c10 li-bullet-0" id="h.lnxbz9">
        <span className="c4">HOW WE USE YOUR PERSONAL DATA</span
        ><span className="c0">.</span>
      </li>
    </ol>
    <p className="c45">
      <span className="c0">We use the Personal Data we collect to: </span>
    </p>
    <ul className="c8 lst-kix_list_22-0 start">
      <li className="c9 li-bullet-0">
        <span className="c0">Communicate with you;</span>
      </li>
      <li className="c9 li-bullet-0">
        <span className="c0"
          >Allow you to communicate with others on the Site;</span
        >
      </li>
      <li className="c9 li-bullet-0">
        <span className="c0"
          >Provide you with information, newsletters and offers;</span
        >
      </li>
      <li className="c9 li-bullet-0">
        <span className="c0">Provide our Services;</span>
      </li>
      <li className="c9 li-bullet-0"><span className="c0">Provide support;</span></li>
      <li className="c9 li-bullet-0">
        <span className="c0">Improve our Services;</span>
      </li>
      <li className="c9 li-bullet-0">
        <span className="c0"
          >Maintain, improve and customize our Site and Services;</span
        >
      </li>
      <li className="c9 li-bullet-0">
        <span className="c0"
          >Defend our legal rights and the rights of others;</span
        >
      </li>
      <li className="c9 li-bullet-0">
        <span className="c0">Efficiently maintain our business; and</span>
      </li>
      <li className="c9 li-bullet-0">
        <span className="c0">Comply with applicable law.</span>
      </li>
    </ul>
    <ol className="c8 lst-kix_list_14-0" start="8" id="p8">
      <li className="c31 c35 li-bullet-0" id="h.35nkun2">
        <span className="c4">INFORMATION WE SHARE WITH THIRD PARTIES</span
        ><span className="c0">.</span>
      </li>
    </ol>
    <p className="c32 c33"><span className="c0"></span></p>
    <p className="c33">
      <span className="c0"
        >We do not sell personal data. &nbsp;We may share the information that
        we collect about you in the following limited ways:</span
      >
    </p>
    <p className="c33 c32"><span className="c0"></span></p>
    <ol className="c8 lst-kix_list_18-1 start" start="1">
      <li className="c3 li-bullet-0" id="h.1ksv4uv">
        <span className="c20">With other users of the Site</span
        ><span className="c0"
          >. &nbsp;If you choose to share your information on the Site, other
          users will be able to see your information and communicate with you.
          &nbsp;</span
        >
      </li>
      <li className="c3 li-bullet-0">
        <span className="c20">With service providers</span
        ><span className="c0"
          >. &nbsp;We may share information with service providers who:</span
        >
      </li>
    </ol>
    <ul className="c8 lst-kix_list_17-1 start">
      <li className="c19 li-bullet-0">
        <span className="c0"
          >perform data services on our behalf (e.g., email, hosting,
          maintenance, backup, analysis, etc.);
        </span>
      </li>
      <li className="c19 li-bullet-0">
        <span className="c0"
          >prepare, deploy and analyze advertising content on our behalf;</span
        >
      </li>
      <li className="c19 li-bullet-0">
        <span className="c0"
          >provide other services on our behalf, such as accounting services,
          and other various services; and</span
        >
      </li>
      <li className="c19 li-bullet-0">
        <span className="c0">process payment information on our behalf.</span>
      </li>
    </ul>
    <ol className="c8 lst-kix_list_24-0 start" start="3">
      <li className="c3 li-bullet-0" id="h.44sinio">
        <span className="c20"
          >To Meet Our Legal Requirements or Exercise Our Rights</span
        ><span className="c0"
          >. &nbsp;We may share information with third parties in the following
          instances:</span
        >
      </li>
    </ol>
    <ul className="c8 lst-kix_list_17-1 start">
      <li className="c19 li-bullet-0">
        <span className="c0">When we are required to do so by law.</span>
      </li>
      <li className="c19 li-bullet-0">
        <span className="c0"
          >In connection with any legal proceedings or prospective legal
          proceedings.</span
        >
      </li>
      <li className="c19 li-bullet-0">
        <span className="c0"
          >To establish, exercise, or defend our legal rights, including
          providing information to others for the purposes of fraud
          prevention.</span
        >
      </li>
      <li className="c19 li-bullet-0">
        <span className="c0"
          >When we reasonably believe it is appropriate to apply to a court or
          other competent authority for disclosure of that Personal Data where,
          in our reasonable opinion, such court or authority would be reasonably
          likely to order disclosure of that Personal data.</span
        >
      </li>
      <li className="c19 li-bullet-0">
        <span className="c0"
          >We may share information with other persons or entities as part of
          our own corporate transactions, such as during financing or any asset
          sale.</span
        >
      </li>
    </ul>
    <ol className="c8 lst-kix_list_14-0" start="9" id="p9">
      <li className="c53 c31 c54 li-bullet-0">
        <span className="c4">HOW TO ACCESS &amp; CONTROL YOUR PERSONAL DATA</span>
      </li>
    </ol>
    <p className="c5 c31">
      <span className="c0"
        >We respect your privacy and provide ways for you to choose whether to
        share your information with us or any third parties. &nbsp;</span
      >
    </p>
    <p className="c5 c7"><span className="c4"></span></p>
    <p className="c5 c31">
      <span className="c21 c23">You may decline to provide information.</span
      ><span className="c0"
        >&nbsp; You have the right to decide not to share information with
        us.</span
      >
    </p>
    <p className="c5 c7"><span className="c0"></span></p>
    <p className="c5 c31">
      <span className="c23 c21">You may request deletion.</span
      ><span className="c0"
        >&nbsp; You may submit a request to update or delete your personal data
        by contacting us at us at </span
      ><span className="c18"
        ><a className="c1" href="mailto:privacy@homefounder.com"
          >team@homefounder.com</a
        ></span
      ><span className="c0">.</span>
    </p>
    <p className="c5 c7"><span className="c0"></span></p>
    <p className="c5 c31">
      <span className="c24 c11"
        >PLEASE NOTE, HOWEVER, THAT IF YOU CHOOSE NOT TO PROVIDE US WITH CERTAIN
        INFORMATION OR ASK US TO DELETE YOUR INFORMATION, WE MAY NOT BE ABLE TO
        PROVIDE YOU WITH SOME OF OUR SERVICES. &nbsp;IN ADDITION, IN SOME
        CIRCUMSTANCES, WE MAY NOT BE ABLE TO MODIFY OR DELETE DATA DUE TO OUR
        OWN OBLIGATIONS.</span
      >
    </p>
    <p className="c5 c7"><span className="c22 c24"></span></p>
    <p className="c5 c31" id="h.2jxsxqh">
      <span className="c23 c21"
        >You may review and request changes to your personal data. &nbsp;</span
      ><span className="c0"
        >You may use your account to access, correct, or view certain personal
        data we have collected which is associated with your account. &nbsp; You
        may also contact us at </span
      ><span className="c18"
        ><a className="c1" href="mailto:privacy@homefounder.com"
          >team@homefounder.com</a
        ></span
      ><span className="c0">&nbsp;to request information or changes. &nbsp;</span>
    </p>
    <p className="c5 c31" id="h.z337ya">
      <span className="c0"><br /></span
      ><span className="c23 c21">You may opt out of marketing communications. </span
      ><span className="c0"
        >You can always choose whether you wish to receive communications we
        send via email, SMS messages, telephone calls and postal mail. &nbsp;To
        do so, contact us at </span
      ><span className="c18"
        ><a className="c1" href="mailto:privacy@homefounder.com"
          >team@homefounder.com</a
        ></span
      ><span className="c0">.</span>
    </p>
    <p className="c5 c17"><span className="c0"></span></p>
    <p className="c31 c39">
      <span className="c21">You may control cookie used.</span
      ><span className="c22"
        >&nbsp; You may opt out of cookies by through your account settings in
        the Site or as described in Section 6 (b) above.</span
      >
    </p>
    <ol className="c8 lst-kix_list_14-0" start="10" id="p10">
      <li className="c12 li-bullet-0" id="h.3j2qqm3">
        <span className="c4">SECURITY</span>
      </li>
    </ol>
    <p className="c13">
      <span className="c0"
        >When you register for an account with us, you may be required to choose
        or will be assigned a password which enables you to access our Site.
        &nbsp;You are responsible for keeping this password confidential and for
        all activity that takes place through your account credentials. Do not
        share your password with anyone. &nbsp;If your password is compromised,
        change &nbsp;your password within your account. &nbsp;If you believe
        your account may have been compromised, please notify us
        immediately.</span
      >
    </p>
    <p className="c13 c32"><span className="c0"></span></p>
    <p className="c13">
      <span className="c0"
        >We take industry standard security measures to protect your
        information. Unfortunately, the transmission of information via the
        internet is not completely secure. &nbsp;Although we will do our best to
        protect your Personal Data, we cannot guarantee the security of your
        data. &nbsp;</span
      >
    </p>
    <p className="c13 c32"><span className="c0"></span></p>
    <ol className="c8 lst-kix_list_14-0" start="11" id="p11">
      <li className="c25 li-bullet-0">
        <span className="c4">LINKS TO THIRD-PARTY SITES</span>
      </li>
    </ol>
    <p className="c48">
      <span className="c0"
        >Our Site may contain links to other websites and companies that may be
        of interest to you. &nbsp;If you click on any of those links, you will
        leave our Site. &nbsp;We are not responsible for third-party websites.
        &nbsp;Therefore, please consult the terms of service and privacy
        policies of those sites when you visit them.</span
      >
    </p>
    <ol className="c8 lst-kix_list_14-0" start="12" id="12">
      <li className="c25 li-bullet-0"><span className="c4">DATA RETENTION</span></li>
    </ol>
    <p className="c48">
      <span className="c0"
        >We will store your Personal Data for as long as: (a) you maintain an
        account with us; (b) you continue using our Site and Services; (c) your
        Personal Data is stored in our back up servers and/or services; and (d)
        as long as we are legally required to retain the Personal Data.
      </span>
    </p>
    <ol className="c8 lst-kix_list_14-0" start="13" id="p13">
      <li className="c14 li-bullet-0">
        <span className="c4">ACCESSIBILITY &nbsp;</span>
      </li>
    </ol>
    <p className="c27 c17"><span className="c0"></span></p>
    <p className="c30 c29" id="h.1y810tw">
      <span className="c0"
        >We are committed to ensuring this Privacy Policy is accessible to
        individuals with disabilities. &nbsp;If you wish to access this Privacy
        Policy in an alternate format, or would like to report an issue you are
        experiencing on our Site or Services, please email us at </span
      ><span className="c18"
        ><a className="c1" href="mailto:privacy@homefounder.com"
          >team@homefounder.com</a
        ></span
      ><span className="c0"
        >. &nbsp;You expressly agree to attempt to resolve any and all issues
        with accessibility directly with us and in good faith prior to
        instituting any legal action against us.</span
      >
    </p>
    <p className="c30 c29 c32"><span className="c0"></span></p>
    <ol className="c8 lst-kix_list_14-0" start="14" id="p14">
      <li className="c5 c31 c53 li-bullet-0">
        <span className="c4 c29">NOTICE TO NEVADA RESIDENTS</span>
      </li>
    </ol>
    <p className="c5 c32"><span className="c0 c29"></span></p>
    <p className="c5">
      <span className="c0 c29"
        >Nevada law allows Nevada residents to opt-out of the sale of certain
        types of personal information. Subject to a number of exceptions, Nevada
        law defines &ldquo;sale&rdquo; to mean the exchange of certain types of
        personal information for monetary consideration to a person for the
        person to license or sell the information to additional persons. We do
        not currently sell personal information as defined in the Nevada law.
        However, if you are a Nevada resident, you still may submit a verified
        request to opt-out of sales and we will record your instructions and
        incorporate them in the future if our policy changes. Opt-out requests
        may be sent to </span
      ><span className="c18 c29"
        ><a className="c1" href="mailto:privacy@homefounder.com"
          >team@homefounder.com</a
        ></span
      ><span className="c0 c29">. </span
      ><span className="c0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    </p>
    <p className="c5 c32"><span className="c24 c22"></span></p>
    <ol className="c8 lst-kix_list_14-0" start="15" id="p15">
      <li className="c10 c29 li-bullet-0">
        <span className="c4 c29">NOTICE TO CALIFORNIA RESIDENTS</span>
      </li>
    </ol>
    <p className="c30 c17 c29"><span className="c22 c29 c47"></span></p>
    <p className="c30">
      <span className="c22">The California Consumer Privacy Act</span
      ><span className="c11">&nbsp;</span><span className="c22">(&ldquo;</span
      ><span className="c11">CCPA</span
      ><span className="c22"
        >&rdquo;) and the California Privacy Rights Act (&ldquo;</span
      ><span className="c11">CPRA</span
      ><span className="c0"
        >&rdquo;) provide certain rights to California residents regarding the
        collection, use, disclosure, and sale of personal information. &nbsp;As
        of the date of last revision of this Privacy Policy, we were not / are
        not subject to the requirements of the CCPA or CPRA. &nbsp;If this
        changes, we will update our Privacy Policy. &nbsp;In the interim, of you
        would like to contact us to access and control your Personal Data,
        please see the options and tools we provide in Section 9 above.
        &nbsp;Please also feel free to contact us with any questions.</span
      >
    </p>
    <p className="c30 c29 c32"><span className="c0"></span></p>
    <p className="c30">
      <span className="c11">Shine the Light Law: &nbsp;</span
      ><span className="c0"
        >If you are a California resident, California Civil Code &sect; 1798.83
        permits you to request information regarding the disclosure of your
        personal information by us to third parties for the third-parties&rsquo;
        direct marketing purposes (as those terms are defined in that statute).
        HOMEFOUNDER does not sell or disclose information to third parties for
        their direct marketing purposes. &nbsp;</span
      >
    </p>
    <p className="c30 c32"><span className="c0"></span></p>
  </div>
        </Policy>
    </Layout>
  )
}

export default PrivacyPage

export const Head: HeadFC = () => <SEO title="Privacy Policy"/>
